
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$customerPanelV3-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$customerPanelV3-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$customerPanelV3-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$customerPanelV3-theme: mat.m2-define-dark-theme((
  color: (
    primary: $customerPanelV3-primary,
    accent: $customerPanelV3-accent,
    warn: $customerPanelV3-warn,
  ),
  typography: mat.m2-define-typography-config(),
  density: 0
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($customerPanelV3-theme);

// Custom Mixins of Partials
@import "assets/css/includes";
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

/* You can add global styles to this file, and also import other style files */

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


:root{
  font-size: 10px;
}
html, body { height: 100%; }
body {
  color: $white;
  font: normal 62.5%/1.47 'Inter', sans-serif;
  margin: 0; 
}



/* 8.3) Page Css Starts*/
.wrapper {
	overflow: hidden;
	position: relative; 
  background-size: cover;
  background: $bg;
  background-size: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  // height: 100vh;
  width: 100%;
}
.doc-container {
  padding: 30px 60px;
  background: linear-gradient(180deg, $bg 0%, rgba($bg, 0) 100%);
}

.container{
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 50px;
}




@media (max-width: 767px) {
	.container {
		padding: 0 30px;
  }
}