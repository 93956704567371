@import "./color";


.page-header {
    position: relative;
    text-align: center;
    width: 100%;
    margin-bottom: 80px;
    /*margin-bottom: 44px;*/
    .mat-mdc-button {
        position: absolute;
        left: 0;
        &>.mat-icon{
            &.back-icon{
                font-size: 3rem;
                height: 3rem;
                width: 3rem;
            }
        }
    }
}


/* Summary Card Starts */
.summary-card {
    box-shadow: 0 0 transparent;
    background: #212121 !important;
    border: 1px solid rgba(255, 255, 255, 0.16) !important;
    padding: 32px;
}
.summary-card .promo {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.summary-card .product-display {
    display: inline-flex;
    width: 100%;
    margin-bottom: 4px;
}
.summary-card .product-display figure {
    width: 55px;
    display: inline-table;
    margin: 0 10px;
    text-align: center;
    font-size: 11px;
    font-weight: 500;
}
.summary-card .product-display figure img {
    width: 100%;
}
.summary-card .promo .mat-mdc-form-field {
    padding: 0;
    width: calc(100% - 74px);
    font-size: 1.4rem;
    .mat-mdc-text-field-wrapper{
        // height: 0;
        .mat-mdc-form-field-flex{
            height: 0;
        }
        .mat-mdc-floating-label{
            // margin-top: -12px;
        }
        .mat-mdc-form-field-infix{
            padding: 17px 0;
            height: 30px;
            min-height: 0;
        }
        .mdc-text-field__input{
            margin-top: 15px ;
        }
    }
    .mat-mdc-form-field-subscript-wrapper{
        display: none;
    }

}


// .summary-card .promo .mat-mdc-form-field-flex > .mat-mdc-form-field-infix {
//     padding: 0.4em 0px 8px;
// }
// .summary-card .promo .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-label {
//     margin-top: -12px;
// }
.summary-card
    .promo
    .mat-mdc-form-field-appearance-outline.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float
    .mat-mdc-form-field-label {
    transform: translateY(-1em) scale(0.75);
}
 
.summary-card .promo .mat-mdc-outlined-button {
    margin-left: 10px;
}
.summary-card .row {
    font-size: 13px;
    font-weight: 500;
    opacity: 0.8;
    margin-bottom: 9px;
}
.summary-card .row .right {
    margin-left: auto;
}
.summary-card .row.total {
    font-size: 17px;
    padding-top: 8px;
    margin-bottom: 24px;
}
.summary-card .mat-flat-button {
    padding: 5px;
}
.summary-card .terms {
    padding: 14px 36px 13px;
    font-size: 11px;
    font-weight: 500;
    line-height: 1.5;
}
.summary-card .terms a {
    text-decoration: none;
    color: #5a87ee;
}

/* Summary Card Ends */



.make-default {
    background: rgb(59 202 92 / 18%);
    border: 1px solid #3bca5c;
    border-radius: 30px;
    padding: 0 13px;
    line-height: 1;
    height: 26px;
    font-size: 11px;
    align-self: center;
  }
  .warning-note {
    background: hsl(43 96% 58% / 0.15);
    padding: 20px;
    border-radius: 10px;
    line-height: 1.5;
    font-weight: 600;
    border: 1px solid #fbc02d;
    display: flex;
    margin: 15px 0;
    font-size: 1.2rem;
    .icon {
      color: #fbc02d !important;
      margin-right: .5rem;
      min-width: 24px;
    }
    .message {
      color: #fbc02d !important;
      display: flex;
      align-items: center;
      span{
        font-weight: 400;
        font-size: 0.85em;
        line-height: 1.3;
        display: block;
      }
    }
  }
  .error-note {
    background: rgb(193 15 48 / 0.15);
    padding: 20px;
    border-radius: 10px;
    line-height: 1.5;
    font-weight: 600;
    border: 1px solid #c10f30;
    display: flex;
    margin: 15px 0;
    font-size: 1.2rem;
    .icon {
      color: #fd153f !important;
      margin-right: .5rem;
      min-width: 24px;
    }
    .message {
      color: #fd153f !important;
    }
  }
  .success-note {
    background: rgb(7 247 104 / 0.15);
    padding: 20px;
    border-radius: 10px;
    line-height: 1.5;
    font-weight: 600;
    border: 1px solid #0cd05b;
    display: flex;
    margin: 15px 0;
    font-size: 1.2rem;
    .icon {
      color: #0ae05f !important;
      margin-right: .5rem;
      min-width: 24px;
    }
    .message {
      color: #0ae05f !important;
    }
  }


.dshow {
    display: none !important;
}


@media (max-width: 876px) {
    .summary-card .terms {
        padding: 14px 16px 13px;
    }
}

@media (max-width: 767px) {
    .dshow {
        display: block !important;
    }
    .dhide {
        display: none !important;
    }
    .page-header {
        .mat-mdc-button {
            left: 0;
            min-width: unset;
        }
        h1 {
            font-size: 2.6rem;
        }
    }
    .summary-card .row {
        flex-direction: row;
    }
}