@import './color';

.btn{
	background: $primary;
  color: $white;
  font-size: 1.4rem;
  font-weight: 500;
  border-radius: 1rem;
  padding: .9rem 1.5rem;
  display: inline-block;
  line-height: 1;
  border: none;
  &.mdc-button{
    background: $primary;
    color: $white;
    padding: 0 15px;
  }
  &.ir{
    display: inline-flex;
    img{
      margin-left: 1rem;
    }
  }
  &:hover,
  &:active,
  &:focus{
    transform: scale(1.03);
    background: darken($primary, 5%);
  }
  &[disabled] {
    background: gray;
    color: #ffffff73;
  }
}

.btn-gradient{
  background: linear-gradient(254.81deg, $blue, $purple);
  color: $white;
  &:hover,
  &:active,
  &:focus{
    transform: scale(1.03);
    background: linear-gradient(254.81deg, $purple, $blue);
  }
}




@media only screen 
and (min-device-width: 480px) 
and (max-device-width: 915px) 
and (orientation: landscape) {
  
  /* .btn{
    font-size: 20px;
    padding: 8px 30px;
  } */
  .btn {
    font-size: 12px;
    padding: 10px 12px;
  }
                    
}