$primary: #00B443;
$secondary: #1EF294;
$tertiary : #7AFF00;

$blue : #1A50FF;
$purple : #BC35E3;


$white: #ffffff;
$black: #000000;

$bg: #141414;


.color-primary{
    color: $primary;
}
.color-secondary{
    color: $secondary;
}
.color-tertiary{
    color: $tertiary;
}
.color-purple{
    color: $purple;
}



// $blue : #2962ff; // Delivered
$red : red; // Rejected
$green : #03bc6d; // Accepted
$orange : #ffd400; // Pending
$violet : #c121dc; // Prepared
// $brown: #dda15e; // On The Way
// $light-blue: #168aad;
// $gray: #6c757d;


// Color Box Mixins

@mixin colorBox($color) {
    background: rgba($color, 0.2);
    color: $color;
}
@mixin colorBorderBox($color, $opacity, $style) {
    background: rgba($color, $opacity);
    color: $color;
    border: 0.1rem $style $color;
    border-radius: 5px;
    display: block;
    padding: 5px 8px;
}

.background{
    background: $bg;
}