/* @font-face {
    font-family: 'ara_jozoorregular';
    src: url('../fonts/ara_jozoor_regular-webfont.woff2') format('woff2'),
         url('../fonts/ara_jozoor_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  
} */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
    font-family: 'Inter', sans-serif;
}