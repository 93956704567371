// breakpoint variables 

@mixin allMobileResponsive {
  @include for-galaxy-s3{@content;}
  @include for-galaxy-s4-s5-note3{@content;}
  @include for-galaxy-s6{@content;}
  @include for-galaxy-s8-and-above{@content;}
  @include for-galaxy-s9{@content;}
  @include for-galaxy-s10-plus{@content;}
  @include for-oneplus-8t{@content;}
  @include for-google-pixel{@content;}
  @include for-google-pixel-xl{@content;}
  @include for-google-pixel2-xl{@content;}
  @include for-google-pixel5{@content;}
  @include for-iphone-xr{@content;}
  @include for-iphone-xs-max{@content;}
  @include for-iphone-13{@content;}
  @include for-iphone-13-promax{@content;}
  @include for-mobile{@content;}
}
// ----------- Galaxy S3 ----------- 
// Landscape 
@mixin for-galaxy-s3 {
  @media screen 
    and (device-width: 320px) 
    and (device-height: 640px) 
    and (-webkit-device-pixel-ratio: 2) 
    and (orientation: landscape) {
      @content;
  }
}

// ----------- Galaxy S4, S5 and Note 3 ----------- 
// Landscape 
@mixin for-galaxy-s4-s5-note3 {
  @media screen 
    and (device-width: 320px) 
    and (device-height: 640px) 
    and (-webkit-device-pixel-ratio: 3) 
    and (orientation: landscape) {
      @content;
  }
}

// ----------- Galaxy S6 ----------- 
// Landscape 
@mixin for-galaxy-s6 {
  @media screen 
    and (device-width: 360px) 
    and (device-height: 640px) 
    and (-webkit-device-pixel-ratio: 4) 
    and (orientation: landscape) {
      @content;
  }
}



// ----------- Galaxy S8+ ----------- 
// Landscape 
@mixin for-galaxy-s8-and-above {
  @media screen 
    and (device-width: 412px)
    and (device-height: 798px)
    and (-webkit-device-pixel-ratio:4)
    and (orientation: landscape) {
      @content;
  }
}




// ----------- Galaxy S9 ----------- 
// Landscape 
@mixin for-galaxy-s9 {
  @media only screen
    and (min-device-width: 360px)
    and (max-device-width: 740px)
    and (-webkit-min-device-pixel-ratio: 4) {
      @content;
  }
}


// ----------- Galaxy s10 plus ----------- 
@mixin for-galaxy-s10-plus {
  @media only screen 
    and (min-width: 412px) 
    and (max-width: 869px) 
    and (orientation: landscape) 
    and (-webkit-device-pixel-ratio: 2.6250) { 
      @content;
  }
}




// ----------- oneplus 8T ----------- 
@mixin for-oneplus-8t {
  @media only screen 
    and (min-width: 384px) 
    and (max-width: 854px) 
    and (orientation: landscape) 
    and (-webkit-device-pixel-ratio: 2.8125) {
      @content;
  }
}



// ----------- Google Pixel ----------- 
// Landscape 
@mixin for-google-pixel {
  @media screen 
  and (device-width: 360px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 3) 
  and (orientation: landscape) {
    @content;
  }
}
  


// ----------- Google Pixel XL ----------- 
// Landscape 
@mixin for-google-pixel-xl {
  @media screen 
    and (device-width: 360px) 
    and (device-height: 640px) 
    and (-webkit-device-pixel-ratio: 4) 
    and (orientation: landscape) {
      @content;
  }
}



// ----------- Google Pixel 2 XL ----------- 
// Landscape 
@mixin for-google-pixel2-xl {
  @media screen 
  and (device-width: 823px) 
  and (device-height: 411px) 
  and (orientation: landscape) {
    @content;
  }
}


// ----------- Google Pixel 5 ----------- 
@mixin for-google-pixel5 {
  @media only screen 
    and (device-width: 851px) 
    and (device-height: 393px) 
    and (orientation: landscape) 
    and (-webkit-min-device-pixel-ratio: 2.75){
      @content;
  }
}



// 1792x828px at 326ppi Iphone XR 
@mixin for-iphone-xr {
  @media only screen 
    and (device-width : 896px) 
    and (device-height : 414px) 
    and (-webkit-device-pixel-ratio : 2) { 
      @content;
  }
}


// 2688x1242px at 458ppi  iPhone XS Max
@mixin for-iphone-xs-max {
  @media only screen 
    and (device-width : 896px) 
    and (device-height : 414px) 
    and (-webkit-device-pixel-ratio : 3) {
      @content;
  }
}


// iPhone 13 and iPhone 13 Pro ( 2532x1170 pixels at 460ppi ) */
@mixin for-iphone-13 {
  @media only screen 
    and (device-width: 844px) 
    and (device-height: 390px) 
    and (orientation: landscape)
    and (-webkit-device-pixel-ratio: 3) {
    @content;
  }
}



// iPhone 13 Pro Max ( 2778x1284 pixels at 458ppi ) */
@mixin for-iphone-13-promax {
  @media only screen 
    and (device-width: 926px) 
    and (device-height: 428px) 
    and (orientation: landscape)
    and (-webkit-device-pixel-ratio: 3) {
      @content;
  }
}

 






// General Mobile
@mixin for-mobile {
  @media only screen 
    and (max-width:767px){
      @content;
  }
}







  