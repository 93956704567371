@import "./color";

a {
	cursor: pointer;
	transition: all .5s ease;
	-webkit-transition: all .5s ease;
	color: inherit;
	text-decoration: none;
	&:hover {
		color: #ffffff;
	}
}
.cursor {
	cursor: pointer;
	outline: none;
}
 

img {
	max-width: 100%;
	height: auto;
	display:block;
	margin:0 auto;
}
b,strong{
    font-weight: 600;
}
.full-width, .full-width img {
	width: 100%;
}
.align-left {
    text-align: left;
}
.align-center {
    text-align: center;
}
.align-end {
    text-align: end;
}
strong {
    font-weight:400;
}
em {
  font-style: italic;
}

u{text-decoration: underline}


// ) Display and Floats
.float{float: left}
.floatR{float: right}

.clear{clear: both;}
.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}
.clearfix:after {
    clear: both;
}

/* Positons */
.p-relative{position: relative}
.p-absolute{position: absolute}
.p-fixed{position: fixed}
.p-static{position: static}

/* Display */
.d-block{display: block}
.d-none{display: none}
.d-flex{display: flex}
.d-inline{display: inline}
.d-inline-block{display: inline-block}
.d-table{display: table}

/* Alignments */
.text-left{text-align: left}
.text-center{text-align: center}
.text-right{text-align: right}

/* Color */
.text-blue{ color: #01aed9}

.hide {
	display: none;
}
   

.no-scroll {
  overflow: hidden;
  height: 100%;
}
 
 
  // Alerts (Starts)
  .alert {
    display: flex !important;
    margin-bottom: 1.5rem;
    padding: 1rem .8rem !important;

    .mat-icon {
		font-size: 20px;
		height: 20px;
		min-width: 20px;
		margin: 2px 1px;
    }

    .txt {
		font-size: 12px;
		line-height: 1.4;
    }

    a {
      color: inherit;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

	.vHidden {
		visibility: hidden;
	}

	button{
		.mat-spinner {
			position: absolute;
			top: calc(50% - 10px);
			left: calc(50% - 10px);
		}
	}

    strong {
      font-weight: 500;
    }

    &.blue {
      @include colorBorderBox($blue, 0.1, dashed);
    }

    &.red {
      @include colorBorderBox($red, 0.1, dashed);
    }

    &.green {
      @include colorBorderBox($green, 0.1, dashed);
    }

    &.orange {
      @include colorBorderBox($orange, 0.1, dashed);
    }

    &.violet {
      @include colorBorderBox($violet, 0.1, dashed);
    }
  }

  // Alerts (Ends)


/*) custom scrollbar Start*/

.scrollbar::-webkit-scrollbar {
	width: 4px;
	background: transparent;
}

.scrollbar::-webkit-scrollbar-track {
	background: transparent;
}

.scrollbar::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0);
	border-radius: 20px;
}

.scrollbar:hover::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.4);
}

/*) custom scrollbar End*/



 


html.overflow, html.overflow body {
	overflow: hidden;
}
  

/* hide scrolling in apple devices */

html.hideScroll {
	overflow-y: scroll;
	height: 100%;
	-webkit-overflow-scrolling: touch;
}

.hideScroll body {
	overflow: hidden;
	height: 100%;
}


  
/* 7.) Animations */

.hiding {
	-webkit-animation: .5s ease-in-out fadeOut;
	animation: .5s ease-in-out fadeOut;
}

.showing {
	opacity: 1;
	-webkit-animation: .5s ease-in-out fadeIn;
	animation: .5s ease-in-out fadeIn;
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@-webkit-keyframes HeightInDown {
	0% {
		height: 0;
		top: 35px;
	}
	50% {
		top: 35px;
		height: 30px;
	}
	100% {
		top: 65px;
		height: 0;
	}
}

@keyframes HeightInDown {
	0% {
		height: 0;
		top: 35px;
	}
	50% {
		top: 35px;
		height: 30px;
	}
	100% {
		top: 65px;
		height: 0;
	}
}

@-webkit-keyframes errorBlink {
	0% {
		border-color: #f44336;
	}
	50% {
		border-color: transparent
	}
	100% {
		border-color: #f44336;
	}
}

@keyframes errorBlink {
	0% {
		border-color: #f44336;
	}
	50% {
		border-color: transparent
	}
	100% {
		border-color: #f44336;
	}
}


@keyframes ellipsis {
    to {
      width: 1.25em;    
    }
}

@keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}


.badge{
	padding: .4rem 0.9rem;
    border-radius: 1.2rem;
    display: inline-block;
    font-size: 1.3rem;
    letter-spacing: 0.5px;
    background: rgba($white, .16);
	color: rgba($white, .5);
}



/* ) Grid System Starts*/

.row {
	display: flex;
}

/*--Clearfix for Row */

.row:before, .row:after {
	content: "";
	display: table;
	clear: both;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-full {
	width: 100%
}

/* ) Grid System Ends*/


/* ) Sub Header */
.sub-header{
	background: linear-gradient(90deg, #132219 0%, #151C1A 26.83%, #10311C 100%);
	padding: 15px 20px;
	margin-bottom: 2rem;
	h3{
		font-size: 2rem;
		margin-bottom: 6px;
	}
	small{
		font-size: 1rem;
		color: rgba($white, .6);
	}
}




/* ) Product listing */
.pro-list{
	display: flex;
	flex-wrap: wrap;
    padding: 0 4rem;
	.item{
		border-radius: 2rem;
		background: #212121;
		border: 1px solid rgba(255, 255, 255, 0.16);
		width: calc(20% - 2rem);
		padding: 2rem;
		margin: 0 1rem 2rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		transition: .3s ease all;
		figure{
			width: 100px;
			height: 100px;
			border-radius: 50%;
			overflow: hidden;
			margin: 0 auto 1.5rem;
			img{
				width: 100%;
				height: 100%;
			}
		}
		.review{
			display: flex;
			align-items: center;
			margin-bottom: .7rem;
			font-size: 1.2rem;
			cursor: pointer;
			.mat-icon{
			  font-size: 1.6rem;
			  height: 1.6rem;
			  width: 1.6rem;
			  margin-right: .5rem;
			  display: flex;
			  align-items: center;
			}
		}
		h4{
			font-size: 1.5rem;
			text-align: center;
			font-weight: 500;
			margin-bottom: .7rem;
		}
		.price{
			font-size: 1.5rem;
			font-weight: 500;
			text-align: center;
			display: block;
			margin: auto 0 2rem 0;
		}
		.btn{
		}
		&:hover,
		&.active{
			border-color: $primary;
			background: linear-gradient(138.4deg, rgba(0, 180, 67, 0.06) 12.37%, rgba(22, 27, 34, 0.087) 47.43%, rgba(0, 180, 67, 0.183) 82.49%);
			box-shadow: 0px 0px 134px rgba(0, 180, 67, 0.1);
		}
	}
}



/* ) Item listing */
.item-list{
	display: flex;
	flex-wrap: wrap;
    padding: 0 4rem;
	.item{
		border-radius: 2rem;
		background: #212121;
		border: 1px solid rgba(255, 255, 255, 0.16);
		width: calc(20% - 20px);
		padding: 3rem 2rem;
		margin: 0 1rem 2rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		transition: .3s ease all;
		position: relative;
		cursor: pointer;
		figure{
			width: 100px;
			height: 100px;
			border-radius: 50%;
			overflow: hidden;
			margin: 0 auto 1.5rem;
			img{
				width: 100%;
				height: 100%;
			}
		}
		h4{
			font-size: 1.5rem;
			text-align: center;
			font-weight: 500;
			margin-bottom: 0;
		}
		.price{
			font-size: 1.5rem;
			font-weight: 500;
			text-align: center;
			display: block;
			margin-top: .7rem;
		}
		.btn{
		}
		.check{
			color: rgba($white, .2);
			position: absolute;
			right: 8px;
			top: 8px;
		}
		&:hover,
		&.active{
			border-color: $primary;
			background: linear-gradient(138.4deg, rgba(0, 180, 67, 0.06) 12.37%, rgba(22, 27, 34, 0.087) 47.43%, rgba(0, 180, 67, 0.183) 82.49%);
			box-shadow: 0px 0px 134px rgba(0, 180, 67, 0.1);
			
		}
		&.active{
			.check{
				color: $secondary;
			}
		}
	}
}

.vHidden {
	visibility: hidden;
}

button{
	mat-spinner {
		position: absolute !important;
		top: calc(50% - 10px);
		left: calc(50% - 10px);
	}
}

.dialog-dark{
	.mat-mdc-dialog-surface{
		background: $bg;
		&::before{
		}
	}
}

.dialog-wrapper{
	padding: 2rem;

	h4{
		font-size: 2.6rem;
	}
	.close{
		position: absolute;
		z-index: 1;
		right: 15px;
		top: 15px;
		border-radius: 50%;
		padding: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: transparent;
		border: 0;
		.mat-icon{
			color: $white;
		}	
	}
	.back{
		position: absolute;
		z-index: 1;
		left: 10px;
		top: 10px;
		border-radius: 50%;
		padding: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: transparent;
		border: 0;
		.mat-icon{
			color: $white;
		}	
	}
}

.authForm-wrapper {
    text-align: center;
	padding: 60px;

    figure {
      &.img-icon {
        max-width: 160px;
        margin: 0 auto 3rem;
      }

      &.restaurant-logo {
        background: linear-gradient(45deg, #bdbdbd, #ebebeb);
        box-shadow: 0 0 15px rgb(0 0 0 / 10%);
        border-radius: 50%;
        width: 180px;
		height: 180px;
		margin: 0 auto 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        img {
          max-width: 130px;
          margin-bottom: 0;
        }
      }

      img {
        height: auto;
      }
    }

	h3{
		font-size: 2.4rem;
    	margin-bottom: .5rem;
	}
	p{
		font-size: 1.4rem;
	}
	

    .secondary-action {
      text-align: center;
	  padding: 1.2rem 0 1.8rem;
	  font-size: 1.3rem;
	  color: rgba($white, .5);
      a {
		color: $secondary;
        cursor: pointer;
      }
    }

	.btn{
		&:not(:disabled){
			background: $primary !important;
		}
	}
	.btn-outline{
		&:not(:disabled){
			background: transparent;
			border: 1px solid $primary;
			color: $primary;
		}
	}

    .numPrefix {
		opacity: 0.7;
		bottom: 0px;
		position: relative;
		left: 8px;
    }
  }


/* Skeleton*/

  .skeleton{
    background: #212121;
    border: 1px solid rgba(255, 255, 255, 0.16);

    background-color: #212121;
    // background-image: linear-gradient(90deg, #333443 0, #343645 4rem, #353746 8rem);
    background-image: linear-gradient(90deg, #1e1e1e 0, #585858 4rem, #353746 8rem);
    background-position: -150% 0;
    background-size: 2rem;
    background-repeat: no-repeat;
    will-change: background-position;
    
    animation: skeleton 1.75s cubic-bezier(0.57, 0.21, 0.69, 1.25) forwards infinite;
    
}
@keyframes skeleton {
    0%,
    10% {
      background-position: -150% 0;
    }
    100% {
      background-position: 350% 0;
    }
}
 


/* Custom Scrollbar*/

.custom-scrollbar {
    overflow: auto;
	transition: .8s ease all;
    // padding-bottom: 8px;
    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
      left: -10px;
      position: relative;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 0;
    }

    &.card-scroll {
      &::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0.2);
        border-radius: 0;
      }
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.2);
      }

      &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.1) ;
      }
    }

  }


  .like-block{
	background-color: rgba($secondary, .16);
	
	display: flex;
	flex-direction: column;
    align-items: center;
    border-radius: 30px;
    font-size: 1.2rem;
    padding: 0 0 8px;
	
	.mat-icon{
		color: $primary;
	}
	
	.favorite{
		background-color: rgba($secondary, .16);
	}

  }

/*
5.) Loops
-------------------------------------*/
@for $i from 0 through 9 {
    .opacity-#{$i*1} {
      opacity: #{$i * 0.1};
    }
}

@media (max-width: 980px) {
	.pro-list .item {
		width: calc(33.33% - 2rem);
	}
	.item-list .item{
		width: calc(25% - 20px);
	}
}


@media (max-width: 767px) {
	.row,
    .row.even {
        flex-direction: column;
    }
    .row .col {
        width: 100%;
    }
	.pro-list .item {
		width: calc(50% - 2rem);
	}
	.item-list .item{
		width: calc(50% - 20px);
	}
    .custom-scrollbar {
      -webkit-overflow-scrolling: touch;
    }

    .custom-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .custom-scrollbar::-webkit-scrollbar-thumb,
    .custom-scrollbar::-webkit-scrollbar-track {
      display: none;
    }

    .custom-scrollbar::-webkit-scrollbar {
      position: static;
    }

}
@media (max-width: 480px) {
	.pro-list .item {
		width: calc(100% - 2rem);
	}
}
 


 


 
