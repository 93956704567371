@import './color';
@import './breakpoint';

a{
    color: $primary;
}
h1 {
	font-size: 5.6rem;
    line-height: 1.2;
    font-weight: 600;
    text-transform: none;
	margin-bottom: 2.2rem;
}

h2 {
    font-size: 4rem;
    line-height: 1.2;
    font-weight: 600;
    text-transform: none;
    margin-bottom: 1rem;
}

  
h3 {
	font-size: 4.5rem;
    line-height: 1.1;
    font-weight: 600;
    text-transform: none;
	margin-bottom: 20px;
}

h4 {
	font-size: 3rem;
    line-height: 1.3;
    font-weight: 600;
    text-transform: none;
	margin-bottom: 20px;
}
h5 {
	font-size: 2.5rem;
    line-height: 1.3;
    font-weight: 600;
    text-transform: none;
	margin-bottom: 20px;
}
 
p {
	font-size: 1.6rem;
	-webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    color: rgba($white, .6);
    margin-bottom: 20px;
}

p:last-child {
  margin-bottom: 0px;
}
ul,ol{
	font-size: 1.8rem;
    color: rgba($white, .6);
    strong{
        color: rgba($white, .9);
        font-weight: 600;
    }
    li{
        margin-bottom: 1.5rem;
    }
}

.gradient-text{
    background: linear-gradient(90.41deg, #8dc641, #a2c240 42.71%, #fbb03c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.gradient-text2{
    background: linear-gradient(89.25deg, $purple, $blue);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.heading-block{
    text-align: center;
    margin: 0 auto 5rem;
    padding-top: 5rem;
    p{
        max-width: 540px;
        margin: 0 auto;
    }
}

@mixin mobileResponsive() {
    h2 {
        font-size: 2.5rem;
    }
    h3 {
        font-size: 2.2rem;
        margin-bottom: 10px;
    }
    h4 {
        font-size: 2rem;
        margin-bottom: 10px;
    }
    p {
        font-size: 1.4rem;
    }
    .heading-block{
        padding: 5rem 2rem 0;
    }
}

@include allMobileResponsive{
    @include mobileResponsive;
}